















import { defineComponent } from "@vue/composition-api";
import { postMessage } from "@/services/embedded/postMessage";

export default defineComponent({
  props: { to: { type: String }, title: { type: String } },
  setup({ to }) {
    if (to !== undefined) postMessage("navigate", { to });
    return {};
  }
});
