import { getDefaultEnumValue } from "@/lib/tools/getDetaultEnumValue";
import { nullToUndefined } from "@/lib/tools/nullToUndefined";
import { ContactType } from "@/types/locations/contact";
import { Location, LocationType } from "@/types/locations/location";
import { AxiosResponse } from "axios";
import { fromItemResponse, fromListResponse } from "../crud/fromResponse";
import { KommunikationsverbindungResponse, LocationResponse } from "./types";

const fromKommunikationsverbindung = ({
  id,
  typ,
  val: value
}: KommunikationsverbindungResponse) => {
  const type = getDefaultEnumValue(ContactType, typ, ContactType.UNKNOWN);
  return { id, type: type, value };
};

export const fromResponse = (response: LocationResponse): Location => {
  const {
    id,
    location_type: locationType,
    kommunikationsverbindungen: contacts
  } = response;
  const type = getDefaultEnumValue(
    LocationType,
    locationType,
    LocationType.UNKNOWN
  );
  return {
    id,
    type,
    co: nullToUndefined(response.co),
    zip: nullToUndefined(response.streetcode),
    city: nullToUndefined(response.city),
    street: nullToUndefined(response.address),
    houseNumber: nullToUndefined(response.addressnumber),
    name: nullToUndefined(response.bezeichnung),
    contacts: contacts.map(fromKommunikationsverbindung)
  };
};

const error = (error: { message?: string; error?: string }) =>
  Error(error.message || error.error || "unknown error");

export const response = ({ status, data }: AxiosResponse) => {
  if (status >= 400) return { error: error(data) };
  try {
    return { data: data ? fromItemResponse(fromResponse)(data) : undefined };
  } catch (error) {
    return { error };
  }
};

export const listResponse = ({ status, data }: AxiosResponse) => {
  if (status >= 400) return { error: error(data) };
  try {
    return { data: fromListResponse(fromResponse)(data) };
  } catch (error) {
    return { error };
  }
};
