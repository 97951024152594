import { Id } from "@/types/crud";

export const fromItemResponse = <From extends Id, To extends Id>(
  transform: (response: From) => To
) => transform;

export const fromListResponse = <From extends Id, To extends Id>(
  transform: (response: From) => To
) => (response: From[]): To[] => response.map(fromItemResponse(transform));

export default { fromItemResponse, fromListResponse };
