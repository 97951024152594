import { RouteConfig } from "vue-router";
import PathNotFound from "@/views/PathNotFound.vue";

export const notFound = (): Array<RouteConfig> => [
  {
    path: "/*",
    name: "PathNotFound",
    component: PathNotFound
  }
];
