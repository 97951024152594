// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isJsonString = (json: string): boolean | any => {
  try {
    const obj = JSON.parse(json);
    if (obj && typeof obj === "object") return obj;
  } catch (e) {
    return false;
  }
  return false;
};
