import { Erlaubnisinhaber } from "@/types/vimbApi/erlaubnisinhaber/erlaubnisinhaber";
import { fetch, Methods } from "@/services/vimbApi/fetch";
import { fromErlaubnisinhaber, toErlaubnisinhaber } from "./erlaubnisinhaber";

const requestUrl = "mvp/erlaubnisinhaber";

export const listErlaubnisinhaber = async () => {
  const { data } = await fetch(requestUrl);
  return data ? data.map(toErlaubnisinhaber) : undefined;
};

export const loadErlaubnisinhaber = async (id: number) => {
  const { data } = await fetch(`${requestUrl}/${id}`);
  return data ? toErlaubnisinhaber(data) : undefined;
};

export const deleteErlaubnisinhaber = async ({ id }: Erlaubnisinhaber) => {
  if (!id) return;
  await fetch(`${requestUrl}/${id}`, Methods.DELETE);
};

export const saveErlaubnisinhaber = async (
  erlaubnisinhaber: Erlaubnisinhaber
) => {
  const { id } = erlaubnisinhaber;
  const url = id ? `${requestUrl}/${id}` : requestUrl;
  const method = id ? Methods.PUT : Methods.POST;
  const request = fromErlaubnisinhaber(erlaubnisinhaber);
  const { data, status } = await fetch(url, method, request);
  return {
    status,
    data: data && status < 400 ? toErlaubnisinhaber(data) : erlaubnisinhaber,
    error: status >= 400 ? data : undefined
  };
};

export const newErlaubnisinhaber = () => {
  return toErlaubnisinhaber({});
};
