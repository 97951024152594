import { ActionTree } from "vuex";
import { RootState } from "@/store/types";
import { Actions, Mutations, State } from "./types";
import {
  deleteErlaubnisinhaber,
  listErlaubnisinhaber,
  loadErlaubnisinhaber,
  newErlaubnisinhaber,
  saveErlaubnisinhaber
} from "@/services/vimbApi/erlaubnisinhaber";
import { Erlaubnisinhaber } from "@/types/vimbApi/erlaubnisinhaber/erlaubnisinhaber";

const actions: ActionTree<State, RootState> = {
  [Actions.LOAD_LIST]: async ({ commit }) => {
    commit(Mutations.SET_LIST_LOADING, true);
    const data = await listErlaubnisinhaber();
    if (data) commit(Mutations.SET_LIST, data);
    commit(Mutations.SET_LIST_LOADING, false);
    return data;
  },
  [Actions.LOAD]: async ({ commit }, id: number) => {
    commit(Mutations.SET_LOADING, true);
    const data = await loadErlaubnisinhaber(id);
    if (data) commit(Mutations.SET, data);
    commit(Mutations.SET_LOADING, false);
    return data;
  },
  [Actions.DELETE]: async ({ commit }, erlaubnisinhaber: Erlaubnisinhaber) => {
    commit(Mutations.SET_LOADING, true);
    await deleteErlaubnisinhaber(erlaubnisinhaber);
    commit(Mutations.REMOVE, erlaubnisinhaber);
    commit(Mutations.SET_LOADING, false);
  },
  [Actions.SAVE]: async ({ commit }, erlaubnisinhaber: Erlaubnisinhaber) => {
    commit(Mutations.SET_LOADING, true);
    const { id } = erlaubnisinhaber;
    const { status, data, error } = await saveErlaubnisinhaber(
      erlaubnisinhaber
    );
    if (!id) commit(Mutations.REMOVE, erlaubnisinhaber);
    if (data?.id) commit(Mutations.SET, data);
    commit(Mutations.SET_LOADING, false);
    return { status, data, error };
  },
  [Actions.CREATE_NEW]: async ({ commit }) => {
    commit(Mutations.SET_LOADING, true);
    await new Promise(resolve => setTimeout(resolve, 500));
    commit(Mutations.SET_LOADING, false);
    return newErlaubnisinhaber();
  }
};

export default actions;
