import {
  Taetigkeit,
  TaetigkeitType
} from "@/types/vimbApi/erlaubnisinhaber/taetigkeit";
import { removeFalseDelete, removeNewDeleted } from "./delete";
import { RawTaetigkeit } from "./types";

const typeByString = (type: string) => {
  if (type === "34d") return TaetigkeitType.TYPE34D;
  if (type === "34f") return TaetigkeitType.TYPE34F;
  if (type === "34c") return TaetigkeitType.TYPE34C;
  if (type === "34i") return TaetigkeitType.TYPE34I;
  return TaetigkeitType.UNKNOWN;
};

const mapRawToTaetigkeit = (item: RawTaetigkeit): Taetigkeit => ({
  id: item.id,
  type: typeByString(item.type),
  registernummer: item.registernummer,
  ausstellendeBehoerde: item.ausstellende_behoerde,
  active: !item.del && !!item.id
});

const mapTaetigkeitToRaw = (item: Taetigkeit): RawTaetigkeit => ({
  id: item.id,
  type: item.type,
  registernummer: item.registernummer,
  // eslint-disable-next-line @typescript-eslint/camelcase
  ausstellende_behoerde: item.ausstellendeBehoerde,
  del: !item.active
});

const findTaetigkeitTypeType = (
  taetigkeiten: RawTaetigkeit[],
  type: TaetigkeitType
): RawTaetigkeit | undefined =>
  taetigkeiten.find(taetigkeit => taetigkeit.type === type);

export const toTaetigkeiten = (
  taetigkeiten: RawTaetigkeit[] = []
): { [key in TaetigkeitType]: Taetigkeit } =>
  Object.fromEntries(
    Object.entries(TaetigkeitType)
      .map(([key, type]) => ({
        key,
        type,
        taetigkeit: findTaetigkeitTypeType(taetigkeiten, type) || { type }
      }))
      .filter(({ taetigkeit }) => taetigkeit.type !== TaetigkeitType.UNKNOWN)
      .map(({ type, taetigkeit }) => [type, mapRawToTaetigkeit(taetigkeit)])
  ) as { [value in TaetigkeitType]: Taetigkeit };

export const fromTaetigkeiten = (
  taetigkeiten: { [key in TaetigkeitType]: Taetigkeit }
): RawTaetigkeit[] =>
  Object.values(taetigkeiten)
    .filter(item => item !== undefined)
    .map(mapTaetigkeitToRaw)
    .filter(removeNewDeleted)
    .map(removeFalseDelete) as RawTaetigkeit[];
