import { Erlaubnisinhaber } from "@/types/vimbApi/erlaubnisinhaber/erlaubnisinhaber";

export enum States {
  LIST = "list",
  LIST_LOADING = "listLoading",
  LOADING = "loading"
}

export enum Actions {
  LOAD_LIST = "loadList",
  LOAD = "load",
  SAVE = "save",
  DELETE = "delete",
  CREATE_NEW = "createNew"
}

export enum Mutations {
  SET_LIST = "setList",
  SET_LIST_LOADING = "setListLoading",
  SET_LOADING = "setLoading",
  SET = "set",
  REMOVE = "remove"
}

export enum Getters {
  GET_USED_TAETIGKEITEN = "getUsedTaetigkeiten"
}

export interface State {
  [States.LIST]: Erlaubnisinhaber[] | undefined;
  [States.LIST_LOADING]: boolean;
  [States.LOADING]: boolean;
}
