import { CrudList } from "@/types/crud";
import { Location } from "@/types/locations/location";

export enum States {
  LOCATIONS = "locations"
}

export enum Actions {
  GET = "get",
  REMOVE = "remove",
  SAVE = "save",
  CREATE = "create",
  CREATE_CONTACT = "createContact",
  SET_CONTACT = "setContact"
}

export enum Mutations {
  SET_LOCATION = "setLocation",
  SET_LOCATION_LOADING = "setLocationLoading",
  SET_LOCATION_ERROR = "setLocationError",
  REMOVE_LOCATION = "removeLocation",
  REMOVE_ALL_LOCATIONS = "removeAllLocations",
  SET_LOCATION_CONTACT = "setLocationContact"
}

export enum Getters {
  BY_ID = "byId",
  GET_CONTACT = "getContact",
  ALL = "all",
  LIST = "list"
}

export type State = {
  [States.LOCATIONS]: CrudList<Location>;
};
