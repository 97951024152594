















import { defineComponent, ref, watch } from "@vue/composition-api";

import AppNavigation from "./components/AppNavigation.vue";
import { resizer } from "./services/embedded/resizer";

export default defineComponent({
  components: { AppNavigation },
  setup() {
    const container = ref();
    watch(container, current => resizer(current));
    return { container };
  }
});
