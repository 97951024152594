import { DeletableContact, DeletedContact } from "@/types/locations/contact";
import { Location } from "@/types/locations/location";
import { KommunikationsverbindungRequest, LocationRequest } from "./types";

const toKommunikationsverbindungRequest = (
  contact: DeletableContact
): KommunikationsverbindungRequest => ({
  id: contact.id > 0 ? contact.id : undefined,
  typ: contact.type,
  val: contact.value,
  del: (contact as DeletedContact).deleted
});

const removeNewDeleted = (contact: DeletableContact) =>
  contact.id > 0 || !(contact as DeletedContact).deleted;

export const request = (
  location: Location | Omit<Location, "id">
): LocationRequest => ({
  id: (location as Location).id,
  // eslint-disable-next-line @typescript-eslint/camelcase
  location_type: location.type,
  co: location.co,
  streetcode: location.zip,
  city: location.city,
  address: location.street,
  addressnumber: location.houseNumber,
  bezeichnung: location.name,
  kommunikationsverbindungen: location.contacts
    .filter(removeNewDeleted)
    .map(toKommunikationsverbindungRequest)
});
