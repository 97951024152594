import { RouteConfig } from "vue-router";
import { bevollmaechtigte } from "./bevollmaechtigte";
import { datenschutz } from "./datenschutz";
import { erlaubnisinhaber } from "./erlaubnisinhaber";
import { social } from "./social";

export const settings = (): Array<RouteConfig> => [
  ...bevollmaechtigte(),
  ...datenschutz(),
  ...erlaubnisinhaber(),
  ...social()
];
