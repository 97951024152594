import EmbeddedLogin from "@/views/EmbeddedLogin.vue";
import { RouteConfig } from "vue-router";

export const login = (): Array<RouteConfig> => [
  {
    path: "/embedded/login",
    name: "EmbeddedLogin",
    component: EmbeddedLogin,
    meta: {
      embedded: {
        require: true,
        to: "Login"
      },
      hideMenu: true
    }
  }
];
