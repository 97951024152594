export enum ErlaubnisumfangType {
  T34F_ABS1_NR1 = "34f_abs1_nr1",
  T34F_ABS1_NR2 = "34f_abs1_nr2",
  T34F_ABS1_NR3 = "34f_abs1_nr3"
}

export interface Erlaubnisumfang {
  id?: number;
  type?: ErlaubnisumfangType;
  active?: boolean;
}
