import { Erlaubnisinhaber } from "@/types/vimbApi/erlaubnisinhaber/erlaubnisinhaber";
import { MutationTree } from "vuex";
import { Mutations, State, States } from "./types";

const eq = (a: Erlaubnisinhaber, b: Erlaubnisinhaber) =>
  a.id === b.id || a === b;

const replace = (list: Erlaubnisinhaber[] = [], inhaber: Erlaubnisinhaber) => {
  if (!inhaber) return list;
  if (!list.find(item => eq(item, inhaber))) return [...list, inhaber];
  return list.map(item => (eq(item, inhaber) ? inhaber : item));
};

const without = (list: Erlaubnisinhaber[] = [], inhaber: Erlaubnisinhaber) =>
  inhaber ? list.filter(item => !eq(item, inhaber)) : list;

const mutations: MutationTree<State> = {
  [Mutations.SET_LIST]: async (state: State, inhaber: Erlaubnisinhaber[]) =>
    (state[States.LIST] = inhaber),
  [Mutations.SET]: async (state: State, inhaber: Erlaubnisinhaber) =>
    (state[States.LIST] = replace(state[States.LIST], inhaber)),
  [Mutations.REMOVE]: async (state: State, inhaber: Erlaubnisinhaber) =>
    (state[States.LIST] = without(state[States.LIST], inhaber)),
  [Mutations.SET_LIST_LOADING]: async (state: State, loading: boolean) =>
    (state[States.LIST_LOADING] = loading),
  [Mutations.SET_LOADING]: async (state: State, loading: boolean) =>
    (state[States.LOADING] = loading)
};

export default mutations;
