import { RootState } from "@/store/types";
import { GetterTree } from "vuex";
import { Getters, State, States } from "./types";
import equal from "deep-equal";

const hasChanged = (state: State) =>
  !equal(state[States.RECOMMENDATION], state[States.ORIGINAL_RECOMMENDATION]);

const isEmpty = (state: State) =>
  Object.values(state[States.RECOMMENDATION] || {}).every(item => !item);

const getters: GetterTree<State, RootState> = {
  [Getters.HAS_CHANGED]: (state: State) => () => hasChanged(state),
  [Getters.IS_EMPTY]: (state: State) => () => isEmpty(state)
};

export default getters;
