import { RootState } from "@/store/types";
import { GetterTree } from "vuex";
import { Getters, State, States } from "./types";

const getters: GetterTree<State, RootState> = {
  [Getters.VERMITTLERNUMBER]: (state: State) => {
    const email = state[States.USER]?.email;
    return email?.match(/@dataoffice.de$/)
      ? email.replace(/@dataoffice.de$/, "")
      : state[States.USER]?.agent?.VermittlernummerVM;
  }
};

export default getters;
