import { RootState } from "@/store/types";
import { Module } from "vuex";
import mutations from "./mutations";
import actions from "./actions";
import getters from "./getters";
import { State, States } from "./types";

const defaultClientId = "VueAppVerwaltung";
const clientId = process.env.VUE_APP_CLIENT_ID || defaultClientId;
const clientSecret = process.env.VUE_APP_CLIENT_SECRET || "";
const appVersion = process.env.VUE_APP_VERSION || "dev";

export const state: State = {
  [States.CLIENT_ID]: clientId,
  [States.CLIENT_SECRET]: clientSecret,
  [States.APP_VERSION]: appVersion,
  [States.PRE_LOGIN_ROUTE]: "/",
  [States.USER]: null,
  [States.TOKEN]:
    process.env.VUE_APP_DEV_TOKEN ||
    window?.localStorage?.getItem("VIMB_API_TOKEN")
};

const module: Module<State, RootState> = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};

export default module;
