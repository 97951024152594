import { RouteConfig } from "vue-router";
import Dashboard from "@/views/Dashboard.vue";

export const dashboard = (): Array<RouteConfig> => [
  {
    path: "/",
    name: "Dashboard",
    component: Dashboard,
    meta: {
      authorization: {
        require: true
      }
    }
  }
];
