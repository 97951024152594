import { RouteConfig } from "vue-router";
import { document } from "./document";
import { login } from "./login";
import { settings } from "./settings";

export const mvp = (): Array<RouteConfig> => [
  ...login(),
  ...document(),
  ...settings()
];
