import { RecommendationError } from "@/types/social/recommendation/error";
import { Recommendation } from "@/types/social/recommendation/recommendation";

export enum States {
  LOADING = "loading",
  RECOMMENDATION = "recommendation",
  ORIGINAL_RECOMMENDATION = "originalRecommendation",
  DEFAULT_RECOMMENDATION = "defaultRecommendation",
  ERROR = "error"
}

export enum Actions {
  GET = "get",
  REMOVE = "remove",
  SAVE = "save",
  GET_DEFAULT = "getDefault"
}

export enum Mutations {
  SET_LOADING = "setLoading",
  SET_RECOMMENDATION = "setRecommendation",
  SET_DEFAULT = "setDefault",
  SET_ERROR = "setError"
}

export enum Getters {
  HAS_CHANGED = "hasChanged",
  IS_EMPTY = "isEmpty"
}

export interface State {
  [States.LOADING]: boolean;
  [States.RECOMMENDATION]?: Recommendation;
  [States.ORIGINAL_RECOMMENDATION]?: Recommendation;
  [States.DEFAULT_RECOMMENDATION]?: Recommendation;
  [States.ERROR]?: RecommendationError;
}
