import { useStore } from "@/lib/useStore";
import { isEmbedded } from "@/services/embedded";
import { Mutations, States } from "@/store/modules/vimbApi/types";
import { Modules } from "@/store/types";
import { NavigationGuard, Route } from "vue-router";

const location = (name: string | undefined) => ({ name });

const authorizationRequired = (route: Route) => {
  const { useMutations, useState } = useStore(Modules.VIMB_API);
  const { [States.TOKEN]: token } = useState([States.TOKEN]);
  if (token.value || !route.meta.authorization?.require) return undefined;
  const { [Mutations.SET_PRE_LOGIN_ROUTE]: setPreLoginRoute } = useMutations([
    Mutations.SET_PRE_LOGIN_ROUTE
  ]);
  setPreLoginRoute(route.fullPath);
  return isEmbedded() ? "EmbeddedLogin" : "MvpLogin";
};

const handleEmbedded = ({ meta }: Route): string | undefined => {
  const { require, restrict, to } = meta.embedded || {};
  if (restrict && isEmbedded()) return to;
  if (require && !isEmbedded()) return to;
  return undefined;
};
const guard = (route: Route) =>
  authorizationRequired(route) || handleEmbedded(route);

export const beforeEach: NavigationGuard<Vue> = (to, from, next) =>
  next(location(guard(to)));
