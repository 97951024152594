export enum Routes {
  BEVOLLMAECHTIGTE = "Bevollmächtigte",
  DATENSCHUTZ = "Datenschutz",
  EMBEDDED_NAVIGATE = "EmbeddedNavigate",
  ERLAUBNISINHABER = "Erlaubnisinhaber",
  MAKLERDOKUMENTE = "Maklerdokumente",
  RECOMMENDATION = "Recommendation"
}

export type Params = { [key: string]: string };
export type Query = { [key: string]: string | (string | null)[] };
