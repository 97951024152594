import { RootState } from "@/store/types";
import { DocumentType } from "@/types/maklerdokumente/DocumentTypes";
import { GetterTree } from "vuex";
import { Getters, State, States } from "./types";

const getValidation = (state: State, type: DocumentType) => {
  const error = state[States.VALIDATION_ERROR];
  const validation = state[States.VALIDATION_RESULT]?.[type];
  const valid = validation && !Object.keys(validation).length;
  const validating = !!state[States.VALIDATING];
  return { validating, error, validation, valid };
};

const getDocument = (state: State, type: DocumentType) => {
  const document = state[States.DOCUMENT][type];
  const error = state[States.DOCUMENT_ERROR][type];
  const loading = !!state[States.DOCUMENT_LOADING][type];
  return { error, document, loading };
};

const getters: GetterTree<State, RootState> = {
  [Getters.GET_VALIDATION]: (state: State) => (type: DocumentType) =>
    getValidation(state, type),
  [Getters.GET_DOCUMENT]: (state: State) => (type: DocumentType) =>
    getDocument(state, type)
};

export default getters;
