import router from "@/router";
import { User } from "@/types/vimbApi/User";
import { MutationTree } from "vuex";
import { Mutations, State, States } from "./types";

const mutations: MutationTree<State> = {
  [Mutations.SET_TOKEN]: async (state: State, token: string) => {
    state[States.TOKEN] = token;
    router.push({ path: state[States.PRE_LOGIN_ROUTE] });
  },
  [Mutations.SET_USER]: async (state: State, user: User) =>
    (state[States.USER] = user),
  [Mutations.SET_PRE_LOGIN_ROUTE]: async (state: State, route: string) =>
    (state[States.PRE_LOGIN_ROUTE] = route)
};

export default mutations;
