import { Geschaeftsform } from "@/types/vimbApi/erlaubnisinhaber/geschaeftsform";

export const toGeschaeftsform = (
  search?: string
): Geschaeftsform | undefined => {
  if (search === "AG") return Geschaeftsform.AG;
  if (search === "GmbH") return Geschaeftsform.GMBH;
  if (search === "GmbH & Co. KG") return Geschaeftsform.GMBHCOKG;
  if (search === "GbR") return Geschaeftsform.GBR;
  if (search === "OHG") return Geschaeftsform.OHG;
  if (search === "UG") return Geschaeftsform.UG;
  if (search === "KG") return Geschaeftsform.KG;
  if (search === "e. K.") return Geschaeftsform.EK;
  if (search === "Einzelkaufmann") return Geschaeftsform.EINZELKAUFMANN;
};
