import { Handelsstand } from "@/types/vimbApi/erlaubnisinhaber/handelsstand";

export const toHandelstand = (
  search: string | undefined
): Handelsstand | undefined => {
  if (search === "84HGB") return Handelsstand.HGB84;
  if (search === "93HGB") return Handelsstand.HGB93;
};

export const fromHandelsstand = (
  handelsstand: Handelsstand | undefined
): string | undefined => {
  if (handelsstand === Handelsstand.HGB84) return "84HGB";
  if (handelsstand === Handelsstand.HGB93) return "93HGB";
  return undefined;
};
