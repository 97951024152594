import { User } from "@/types/vimbApi/User";

export enum States {
  TOKEN = "token",
  USER = "user",
  CLIENT_ID = "clientId",
  CLIENT_SECRET = "clientSecret",
  APP_VERSION = "appVersion",
  PRE_LOGIN_ROUTE = "preLoginRoute"
}

export enum Actions {
  REQUEST_TOKEN_FROM_PARENT = "requestTokenFromParent",
  LOAD_ME = "loadMe"
}

export enum Mutations {
  SET_USER = "setUser",
  SET_TOKEN = "setToken",
  SET_PRE_LOGIN_ROUTE = "setPreLoginRoute"
}

export enum Getters {
  VERMITTLERNUMBER = "vermittlerNumber"
}

export type State = {
  [States.PRE_LOGIN_ROUTE]: string;
  [States.TOKEN]?: string;
  [States.CLIENT_ID]: string;
  [States.CLIENT_SECRET]: string;
  [States.APP_VERSION]: string;
  [States.USER]: User | null;
};
