import { Location } from "@/types/locations/location";
import { fetch, Methods } from "@/services/vimbApi/fetch";
import { request } from "./request";
import { response, listResponse } from "./response";

let locationIncrement = -1;
let contactIncrement = -1;

export const load = async () => listResponse(await fetch("mvp/locations"));

export const get = async (id: number) =>
  response(await fetch(`mvp/locations/${id}`));

export const remove = async (id: number) =>
  response(await fetch(`mvp/locations/${id}`, Methods.DELETE));

export const put = async (id: number, location: Location) =>
  response(await fetch(`mvp/locations/${id}`, Methods.PUT, request(location)));

export const post = async (location: Omit<Location, "id">) =>
  response(await fetch("mvp/locations", Methods.POST, request(location)));

export const create = () => locationIncrement--;

export const createContact = () => contactIncrement--;

export default { load, get, remove, put, post, create, createContact };
