import { error } from "@/services/vimbApi/fetch";
import { RouteConfig } from "vue-router";

export const api = (): Array<RouteConfig> => [
  {
    path: "/error/api",
    name: "apiError",
    props(route) {
      return { error: error(), ...route.params };
    },
    component: () => import("@/views/ApiError.vue")
  }
];
