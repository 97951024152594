import { DocumentType } from "@/types/maklerdokumente/DocumentTypes";
import { fetch, Methods } from "../vimbApi/fetch";
import { response, fromDocumentValidation, fromDocument } from "./response";

export const validateDocuments = async (vermittlerNumber: string) =>
  response(
    await fetch(`/mvp/agents/${vermittlerNumber}/validateTemplates`),
    fromDocumentValidation
  );

export const loadDocument = async (type: DocumentType) =>
  response(
    await fetch(`/mvp/formulare/${type}`, Methods.POST, undefined, {
      responseType: "arraybuffer"
    }),
    fromDocument
  );

export default { validateDocuments, loadDocument };
