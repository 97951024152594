export enum TaetigkeitType {
  TYPE34D = "34d",
  TYPE34F = "34f",
  TYPE34C = "34c",
  TYPE34I = "34i",
  UNKNOWN = ""
}

export interface Taetigkeit {
  id?: number;
  type: TaetigkeitType;
  registernummer?: string;
  ausstellendeBehoerde?: string;
  active: boolean;
}
