import {
  Location,
  LocationType
} from "@/types/vimbApi/erlaubnisinhaber/location";
import { removeFalseDelete, removeNewDeleted } from "./delete";
import { RawLocation } from "./types";

const toLocationType = (search: string): LocationType => {
  if (search === "erlaubnisinhaber.betriebliche_anschrift")
    return LocationType.BETRIEBLICHE_ANSCHRIFT;
  if (search === "erlaubnisinhaber.erlaubnisbehoerde")
    return LocationType.ERLAUBNISBEHOERDE;
  if (search === "erlaubnisinhaber.registerbehoerde")
    return LocationType.REGISTERBEHOERDE;
  if (search === "erlaubnisinhaber.personenhandelsgesellschaft")
    return LocationType.PERSONENHANDELSGESELLSCHAFT;
  return LocationType.UNKNOWN;
};

const toLocation = ({
  id,
  location_type: type,
  bezeichnung: name = "",
  address: street = "",
  addressnumber: houseNumber = "",
  city = "",
  streetcode: zip = "",
  co = ""
}: RawLocation): Location => ({
  id,
  type: toLocationType(type),
  name,
  street,
  houseNumber,
  city,
  zip,
  co
});

const makeActive = (location: Location) => ({ ...location, active: true });

const defaultActive = (type: LocationType) =>
  type !== LocationType.REGISTERBEHOERDE;

const defaultLocation = (type: LocationType) => ({
  type,
  active: defaultActive(type)
});

const isNotUnknownType = ({ type }: Location) => type !== LocationType.UNKNOWN;

export const toLocations = (
  locations: RawLocation[] = []
): { [key in LocationType]: Location } =>
  Object.fromEntries(
    Object.entries(LocationType)
      .map(([key, type]) => ({
        key,
        type,
        locations: locations
          .filter(location => location.location_type === type)
          .map(toLocation)
      }))
      .filter(isNotUnknownType)
      .map(({ locations, ...data }) => ({
        ...data,
        locations: locations.map(makeActive)
      }))
      .map(({ type, locations }) => {
        const [location] = locations;
        if (type === LocationType.PERSONENHANDELSGESELLSCHAFT)
          return [type, locations];
        return [type, location || defaultLocation(type)];
      })
  ) as { [key in LocationType]: Location };

export const fromLocations = (
  locations: {
    [key in LocationType]: Location;
  },
  deleteAllHandelsgesellschaft: boolean
) =>
  Object.values(locations)
    .map(location => (location instanceof Array ? location : [location]))
    .flat()
    .map((location: Location) => ({
      id: location.id,
      // eslint-disable-next-line @typescript-eslint/camelcase
      location_type: location.type,
      bezeichnung: location.name,
      address: location.street,
      addressnumber: location.houseNumber,
      city: location.city,
      streetcode: location.zip,
      co: location.co,
      del:
        !location.active ||
        (deleteAllHandelsgesellschaft &&
          location.type === LocationType.PERSONENHANDELSGESELLSCHAFT)
    }))
    .filter(removeNewDeleted)
    .map(removeFalseDelete);
