import { RecommendationError } from "@/types/social/recommendation/error";
import { Recommendation } from "@/types/social/recommendation/recommendation";
import Vue from "vue";
import { MutationTree } from "vuex";
import { Mutations, State, States } from "./types";

const setLoading = (state: State, loading = true) =>
  (state[States.LOADING] = loading);

const setRecommendation = (state: State, recommendation: Recommendation) => {
  Vue.set(state, States.RECOMMENDATION, { ...recommendation });
  Vue.set(state, States.ORIGINAL_RECOMMENDATION, { ...recommendation });
  Vue.set(state, States.ERROR, undefined);
};

const setDefault = (state: State, recommendation: Recommendation) =>
  Vue.set(state, States.DEFAULT_RECOMMENDATION, { ...recommendation });

const setError = (state: State, error: RecommendationError) =>
  Vue.set(state, States.ERROR, error);

const mutations: MutationTree<State> = {
  [Mutations.SET_LOADING]: setLoading,
  [Mutations.SET_RECOMMENDATION]: setRecommendation,
  [Mutations.SET_DEFAULT]: setDefault,
  [Mutations.SET_ERROR]: setError
};

export default mutations;
