import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import { app } from "./app";
import { beforeEach } from "./beforeEach";
import { embedded } from "./embedded";
import { error } from "./error";
import { mvp } from "./mvp";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  ...embedded(),
  ...mvp(),
  ...app(),
  ...error()
];

const router = new VueRouter({ routes });
router.beforeEach(beforeEach);

export default router;
