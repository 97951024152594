import { CrudItem, isCrudItem } from "@/types/crud";
import { DeletableContact } from "@/types/locations/contact";
import { Location } from "@/types/locations/location";
import { MutationTree } from "vuex";
import { Mutations, State, States } from "./types";

const setLocation = (
  state: State,
  location: Location | CrudItem<Location>
): CrudItem<Location> => {
  const value = isCrudItem(location) ? location.value : location;
  const error = isCrudItem(location) ? location.error : undefined;
  const loading = isCrudItem(location) ? !!location.loading : false;
  state[States.LOCATIONS] = {
    ...state[States.LOCATIONS],
    [value.id]: { value, error, loading }
  };
  return state[States.LOCATIONS][value.id];
};

const setLocationLoading = (state: State, id: number) => {
  const current = state[States.LOCATIONS][id] || { value: { id } };
  setLocation(state, { ...current, loading: true });
};

const setLocationError = (
  state: State,
  { id, error }: { id: number; error: Error }
) => {
  const current = state[States.LOCATIONS][id] || { value: { id } };
  setLocation(state, { ...current, error, loading: false });
};

const removeLocation = (state: State, id: number) => {
  const { [id]: removed, ...rest } = state[States.LOCATIONS];
  state[States.LOCATIONS] = rest;
};

const removeAllLocations = (state: State) => {
  state[States.LOCATIONS] = {};
};

const setLocationContact = (
  state: State,
  { locationId, contact }: { locationId: number; contact: DeletableContact }
) => {
  const location = state[States.LOCATIONS][locationId];
  if (location === undefined) return;
  const { value } = location;
  const existing = value.contacts || [];
  const index = existing.findIndex(({ id }) => id === contact.id);
  const contacts =
    index < 0
      ? [...existing, contact]
      : [...existing.slice(0, index), contact, ...existing.slice(index + 1)];
  setLocation(state, { ...location, value: { ...value, contacts } });
};

const mutations: MutationTree<State> = {
  [Mutations.SET_LOCATION]: setLocation,
  [Mutations.SET_LOCATION_LOADING]: setLocationLoading,
  [Mutations.SET_LOCATION_ERROR]: setLocationError,
  [Mutations.REMOVE_LOCATION]: removeLocation,
  [Mutations.REMOVE_ALL_LOCATIONS]: removeAllLocations,
  [Mutations.SET_LOCATION_CONTACT]: setLocationContact
};

export default mutations;
