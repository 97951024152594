import { RouteConfig } from "vue-router";
import MvpLogin from "@/views/MvpLogin.vue";

export const login = (): Array<RouteConfig> => [
  {
    path: "/mvp/login",
    name: "MvpLogin",
    component: MvpLogin,
    meta: {
      embedded: {
        restrict: true,
        to: "EmbeddedLogin"
      },
      hideMenu: true
    }
  }
];
