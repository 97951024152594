import { DeletableContact } from "./contact";

export enum LocationType {
  POSTAL = "postal",
  PLACE = "place",
  REGION = "region",
  STATE = "state",
  COUNTRY = "country",
  URL = "url",
  NETWORK = "network",
  EMAIL = "email",
  PHONE = "phone",
  MOBILE = "mobile",
  FAX = "fax",
  OTHER = "other",
  BEHOERDE_34D = "behoerde_34d",
  BEHOERDE_34F = "behoerde_34f",
  BEHOERDE_34I = "behoerde_34i",
  BEHOERDE_34C_AUSSTELLENDE = "behoerde_34c_ausstellende",
  BEHOERDE_34C_AUFSICHTS = "behoerde_34c_aufsichts",
  DATENSCHUTZBEAUFTRAGTER = "datenschutzbeauftragter",
  DATENSCHUTZBEHOERDE = "datenschutzbehoerde",
  WEITERE_BEVOLLMAECHTIGTE = "weitere_bevollmaechtigte",
  ERLAUBNISINHABER_BETRIEBLICHE_ANSCHRIFT = "erlaubnisinhaber.betriebliche_anschrift",
  ERLAUBNISINHABER_ERLAUBNISBEHOERDE = "erlaubnisinhaber.erlaubnisbehoerde",
  ERLAUBNISINHABER_REGISTERBEHOERDE = "erlaubnisinhaber.registerbehoerde",
  ERLAUBNISINHABER_DATENSCHUTZBEAUFTRAGTER = "erlaubnisinhaber.datenschutzbeauftragter",
  ERLAUBNISINHABER_DATENSCHUTZBEHOERDE = "erlaubnisinhaber.datenschutzbehoerde",
  ERLAUBNISINHABER_PERSONENHANDELSGESELLSCHAFT = "erlaubnisinhaber.personenhandelsgesellschaft",
  UNKNOWN = ""
}

export type Location = {
  id: number;
  type: LocationType;
  name?: string;
  co?: string;
  city?: string;
  zip?: string;
  street?: string;
  houseNumber?: string;
  contacts: DeletableContact[];
};

type Config = { title: string };

export const config: { [key in LocationType]: Config } = {
  [LocationType.POSTAL]: {
    title: "Postleitzahl"
  },
  [LocationType.PLACE]: {
    title: "Stadtteil"
  },
  [LocationType.REGION]: {
    title: "Region"
  },
  [LocationType.STATE]: {
    title: "Bundesland"
  },
  [LocationType.COUNTRY]: {
    title: "Land"
  },
  [LocationType.URL]: {
    title: "URL"
  },
  [LocationType.NETWORK]: {
    title: "Netzwerk"
  },
  [LocationType.EMAIL]: {
    title: "E-Mail"
  },
  [LocationType.PHONE]: {
    title: "Telefon"
  },
  [LocationType.MOBILE]: {
    title: "Mobil"
  },
  [LocationType.FAX]: {
    title: "Fax"
  },
  [LocationType.OTHER]: {
    title: "Sonstige"
  },
  [LocationType.BEHOERDE_34D]: {
    title: "Behörde 34d"
  },
  [LocationType.BEHOERDE_34F]: {
    title: "Behörde 34f"
  },
  [LocationType.BEHOERDE_34I]: {
    title: "Behörde 34i"
  },
  [LocationType.BEHOERDE_34C_AUSSTELLENDE]: {
    title: "34c ausstellende Behörde"
  },
  [LocationType.BEHOERDE_34C_AUFSICHTS]: {
    title: "34c Aufsichtsbehörde "
  },
  [LocationType.DATENSCHUTZBEAUFTRAGTER]: {
    title: "Datenschutzbeauftragter"
  },
  [LocationType.DATENSCHUTZBEHOERDE]: {
    title: "Datenschutzbehörde"
  },
  [LocationType.WEITERE_BEVOLLMAECHTIGTE]: {
    title: "Weitere Bevollmächtigte"
  },
  [LocationType.ERLAUBNISINHABER_BETRIEBLICHE_ANSCHRIFT]: {
    title: "Betriebliche Anschrift"
  },
  [LocationType.ERLAUBNISINHABER_ERLAUBNISBEHOERDE]: {
    title: "Erlaubnisbehörde"
  },
  [LocationType.ERLAUBNISINHABER_REGISTERBEHOERDE]: {
    title: "Registerbehörde"
  },
  [LocationType.ERLAUBNISINHABER_DATENSCHUTZBEAUFTRAGTER]: {
    title: "Datenschutzbeauftragter"
  },
  [LocationType.ERLAUBNISINHABER_DATENSCHUTZBEHOERDE]: {
    title: "Datenschutzbehörde"
  },
  [LocationType.ERLAUBNISINHABER_PERSONENHANDELSGESELLSCHAFT]: {
    title: "Personenhandelsgesellschaft"
  },
  [LocationType.UNKNOWN]: {
    title: "unbekannt"
  }
};
