import { RootState } from "@/store/types";
import { CrudList } from "@/types/crud";
import { Location } from "@/types/locations/location";
import { GetterTree } from "vuex";
import { Getters, State, States } from "./types";

const locations = (state: State) => state[States.LOCATIONS];

const getById = (locations: CrudList<Location>, id: number) => locations[id];

const contactById = (
  locations: CrudList<Location>,
  locationId: number,
  contactId: number
) =>
  getById(locations, locationId)?.value.contacts?.find(
    ({ id }) => id === contactId
  );

const getters: GetterTree<State, RootState> = {
  [Getters.ALL]: (state: State) => () => Object.values(state[States.LOCATIONS]),
  [Getters.BY_ID]: (state: State) => (id: number) =>
    getById(locations(state), id),
  [Getters.GET_CONTACT]: (state: State) => (
    locationId: number,
    contactId: number
  ) => contactById(locations(state), locationId, contactId),
  [Getters.LIST]: (state: State) => () => state[States.LOCATIONS][0]
};

export default getters;
