import Navigate from "@/views/embedded/Navigate.vue";

import { RouteConfig } from "vue-router";
import { Params, Routes } from "../types";

export const navigate = (): Array<RouteConfig> => [
  {
    path: "/embedded/navigate/:to/:title?",
    props(route) {
      const params: Params = { ...route.params };
      const { to, title } = params;
      return { to, title };
    },
    name: Routes.EMBEDDED_NAVIGATE,
    component: Navigate,
    meta: {
      embedded: {
        require: true,
        to: "Login"
      },
      authorization: {
        require: true
      },
      hideMenu: true
    }
  }
];
