import { DocumentType } from "@/types/maklerdokumente/DocumentTypes";
import { DocumentValidationResult } from "@/types/maklerdokumente/DocumentValidationResult";
import { Mutation, MutationTree } from "vuex";
import { Mutations, State, States } from "./types";

const setValidating = (state: State, validating = true) => {
  state[States.VALIDATING] = validating;
};

const setValidationResult = (
  state: State,
  result: DocumentValidationResult
) => {
  state[States.VALIDATION_RESULT] = result;
  setValidating(state, false);
};

const setValidationError = (state: State, error: Error) => {
  state[States.VALIDATION_ERROR] = error;
};

const setDocumentLoading = (
  state: State,
  type: DocumentType,
  loading = true
) => {
  const current = state[States.DOCUMENT_LOADING];
  state[States.DOCUMENT_LOADING] = { ...current, [type]: loading };
};

const setDocument = (
  state: State,
  { type, data }: { type: DocumentType; data: string }
) => {
  const current = state[States.DOCUMENT];
  state[States.DOCUMENT] = { ...current, [type]: data };
  setDocumentLoading(state, type, false);
};

const setDocumentError = (
  state: State,
  { type, error }: { type: DocumentType; error?: Error }
) => {
  const current = state[States.DOCUMENT_ERROR];
  state[States.DOCUMENT_ERROR] = { ...current, [type]: error };
};

const mutations: MutationTree<State> &
  { [key in Mutations]: Mutation<State> } = {
  [Mutations.SET_VALIDATING]: state => setValidating(state),
  [Mutations.SET_VALIDATION_RESULT]: setValidationResult,
  [Mutations.SET_VALIDATION_ERROR]: setValidationError,
  [Mutations.SET_DOCUMENT_LOADING]: setDocumentLoading,
  [Mutations.SET_DOCUMENT]: setDocument,
  [Mutations.SET_DOCUMENT_ERROR]: setDocumentError
};

export default mutations;
