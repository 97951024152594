import { useStore } from "@/lib/useStore";
import router from "@/router";
import { States } from "@/store/modules/vimbApi/types";
import { Modules } from "@/store/types";
import axios, { AxiosError, AxiosRequestConfig } from "axios";

export enum Methods {
  GET = "get",
  POST = "post",
  PUT = "put",
  DELETE = "delete"
}

const defaultBasePath = "https://api.24finance.de/api/";
const baseURL = process.env.VUE_APP_API_URL || defaultBasePath;

const errors: AxiosError[] = [];

export const error = () => errors.pop();

export const fetch = async (
  url: string,
  method: Methods = Methods.GET,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data?: any,
  config?: AxiosRequestConfig
) => {
  const store = useStore(Modules.VIMB_API);
  const {
    [States.CLIENT_ID]: clientId,
    [States.CLIENT_SECRET]: clientSecret,
    [States.APP_VERSION]: appVersion,
    [States.TOKEN]: token
  } = store.useState([
    States.CLIENT_ID,
    States.CLIENT_SECRET,
    States.APP_VERSION,
    States.TOKEN
  ]);
  try {
    return await axios({
      ...config,
      url,
      method,
      baseURL,
      data,
      validateStatus: () => true,
      headers: {
        ...config?.headers,
        "client-id": clientId.value,
        "client-secret": clientSecret.value,
        appVersion: appVersion.value,
        Authorization: `Bearer ${token.value}`
      }
    });
  } catch (error) {
    errors.push(error);
    router.push({ name: "apiError" });
    return error;
  }
};
