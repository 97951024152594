import { RootState } from "@/store/types";
import { TaetigkeitType } from "@/types/vimbApi/erlaubnisinhaber/taetigkeit";
import { GetterTree } from "vuex";
import { Getters, State, States } from "./types";

const getters: GetterTree<State, RootState> = {
  [Getters.GET_USED_TAETIGKEITEN]: (state: State) => (): TaetigkeitType[] =>
    state[States.LIST]
      ? (state[States.LIST]
          ?.map(({ taetigkeiten }) => Object.values(taetigkeiten))
          .flat()
          .filter(taetigkeit => taetigkeit?.active)
          .map(taetigkeit => taetigkeit?.type)
          .filter(Boolean) as TaetigkeitType[])
      : []
};

export default getters;
