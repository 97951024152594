import { isJsonString } from "@/lib/tools/isJsonString";
import { postMessage } from "./postMessage";

export const isEmbedded = () =>
  process.env.VUE_APP_FORCE_EMBEDDED || window.parent !== window;

const isMessageOfType = (data: string, ...types: string[]) =>
  isJsonString(data) && types.includes(JSON.parse(data).type);

export const requestLoginToken = async () =>
  new Promise((resolve, reject) => {
    postMessage("getLoginToken");
    const waitForToken = (event: MessageEvent) => {
      if (!isMessageOfType(event.data, "loginToken", "loginTokenError")) return;
      window.removeEventListener("message", waitForToken);
      const { type, token, error } = JSON.parse(event.data);
      if (type === "loginToken") resolve(token);
      else reject(error);
    };
    window.addEventListener("message", waitForToken);
    setTimeout(() => reject("timeout"), 60 * 1000);
  });

// @TODO move to other file and load in app
window.addEventListener("message", (event: MessageEvent) => {
  if (!isMessageOfType(event.data, "positionsAndColors")) return;

  const data = JSON.parse(event.data).data;
  const { windowHeight, iframeTop } = data.positions;
  const colors = data.colors;

  const dialogContentHeight = windowHeight - Math.max(0, iframeTop);
  const dialogContentTop = -iframeTop;
  document.body.style.setProperty(
    "--dialog-content-height",
    `${dialogContentHeight}px`
  );
  document.body.style.setProperty(
    "--dialog-content-top",
    `${dialogContentTop}px`
  );
  if (!colors.primaryColor) return;

  const toKebabCase = (str: string) => {
    return str.replace(/([a-z])([A-Z])/g, "$1-$2").toLowerCase();
  };

  const applyColorsToCSS = (colors: { [key: string]: string }) => {
    if (colors.primaryColor) {
      document.body.style.setProperty("--v-primary-base", colors.primaryColor);
      document.body.style.setProperty("--v-anchor-base", colors.primaryColor);
    }

    Object.entries(colors).forEach(([key, value]) => {
      if (key === "primaryColor" || !value) return;
      const kebabKey = toKebabCase(key);
      document.body.style.setProperty(`--${kebabKey}`, value);
    });
  };

  applyColorsToCSS(colors);
});
