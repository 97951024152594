/* eslint-disable @typescript-eslint/no-explicit-any */
import { store } from "@/store/store";
import { computed } from "@vue/composition-api";
import { DispatchOptions } from "vuex";

export const useStore = (nameSpace: string) => {
  return {
    useActions: (map: string[]) => {
      const obj: any = {};
      map.forEach(m => {
        obj[m] = async (payload: any, options: DispatchOptions) => {
          return store.dispatch(`${nameSpace}/${m}`, payload, options);
        };
      });
      return obj;
    },
    useGetters: (map: string[]) => {
      const obj: any = {};
      map.forEach(m => {
        obj[m] = store.getters[`${nameSpace}/${m}`];
      });
      return obj;
    },
    useComputed: (map: string[]) => {
      const obj: any = {};
      map.forEach(m => {
        obj[m] = computed((): any => store.getters[`${nameSpace}/${m}`]);
      });
      return obj;
    },
    useMutations: (map: string[]) => {
      const obj: any = {};
      map.forEach(m => {
        obj[m] = async (payload: any, options: DispatchOptions) => {
          return store.commit(`${nameSpace}/${m}`, payload, options);
        };
      });
      return obj;
    },
    useState: (map: string[]) => {
      const obj: any = {};
      map.forEach(m => {
        obj[m] = computed((): any => (store.state as any)[nameSpace][m]);
      });
      return obj;
    },
    useNestedState: (map: string[]) => {
      const obj: any = {};
      const modules = nameSpace.split("/");
      map.forEach(m => {
        obj[m] = computed((): any => {
          return modules.reduce((obj, mod) => {
            return obj[mod];
          }, store.state as any)[m];
        });
      });
      return obj;
    }
  };
};

export const useStores = (nameSpaces: string[]) => nameSpaces.map(useStore);
