export enum ContactType {
  PHONE = "Telefon",
  MOBILE = "Mobil",
  EMAIL = "E-Mail",
  FAX = "Fax",
  WEBSITE = "Webseite",
  UNKNOWN = ""
}

export type Contact = {
  id: number;
  type: ContactType;
  value: string;
};

type Deleted = {
  deleted: true;
};

export type DeletedContact = Deleted & Contact;

export type DeletableContact = Contact | DeletedContact;

type Config = {
  inputType: "tel" | "email" | "url" | "text";
  faIcon: string;
  regexp?: RegExp;
};

export const config: { [key in ContactType]: Config } = {
  [ContactType.PHONE]: { inputType: "tel", faIcon: "fas fa-phone" },
  [ContactType.MOBILE]: { inputType: "tel", faIcon: "fas fa-mobile" },
  [ContactType.FAX]: { inputType: "tel", faIcon: "fas fa-fax" },
  [ContactType.EMAIL]: {
    inputType: "email",
    faIcon: "fas fa-envelope",
    regexp:
      process.env.VUE_APP_REGEXP_EMAIL_VALIDATION &&
      RegExp(process.env.VUE_APP_REGEXP_EMAIL_VALIDATION)
  },
  [ContactType.WEBSITE]: { inputType: "url", faIcon: "fas fa-globe" },
  [ContactType.UNKNOWN]: { inputType: "text", faIcon: "fas fa-question-circle" }
};
