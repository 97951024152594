import { ActionTree, Commit } from "vuex";
import { RootState } from "@/store/types";
import service from "@/services/social/recommendation";
import { Actions, Mutations, State } from "./types";
import { Recommendation } from "@/types/social/recommendation/recommendation";
import { RecommendationError } from "@/types/social/recommendation/error";

const handleResponse = (
  commit: Commit,
  response: Recommendation | RecommendationError
) => {
  if ("statusCode" in response) commit(Mutations.SET_ERROR, response);
  else commit(Mutations.SET_RECOMMENDATION, response);
};

const get = async (commit: Commit) => {
  commit(Mutations.SET_LOADING);
  handleResponse(commit, await service.get());
  commit(Mutations.SET_LOADING, false);
};

const getDefault = async (commit: Commit) => {
  commit(Mutations.SET_LOADING);
  const response = await service.getDefault();
  if (!("statusCode" in response)) commit(Mutations.SET_DEFAULT, response);
  commit(Mutations.SET_LOADING, false);
};

const save = async (commit: Commit, recommendation?: Recommendation) => {
  if (!recommendation) return;
  const { create } = recommendation || {};
  commit(Mutations.SET_LOADING);
  if (create) handleResponse(commit, await service.post(recommendation));
  else handleResponse(commit, await service.put(recommendation));
  commit(Mutations.SET_LOADING, false);
};

const remove = async (commit: Commit, recommendation: Recommendation) => {
  if (!recommendation || recommendation.create) return;
  commit(Mutations.SET_LOADING);
  handleResponse(commit, await service.remove());
  commit(Mutations.SET_LOADING, false);
};

const actions: ActionTree<State, RootState> = {
  [Actions.GET]: ({ commit }) => get(commit),
  [Actions.GET_DEFAULT]: ({ commit }) => getDefault(commit),
  [Actions.SAVE]: ({ commit }, recommendation: Recommendation) =>
    save(commit, recommendation),
  [Actions.REMOVE]: ({ commit }, recommendation: Recommendation) =>
    remove(commit, recommendation)
};

export default actions;
