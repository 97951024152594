import { Commit } from "vuex";
import { ActionTree } from "@/store/types";
import { Actions, Mutations, State } from "./types";
import service from "@/services/maklerdokumente";
import { DocumentType } from "@/types/maklerdokumente/DocumentTypes";

const validateDocument = async (commit: Commit, id: string) => {
  commit(Mutations.SET_VALIDATING);
  const { data, error } = await service.validateDocuments(id);
  commit(Mutations.SET_VALIDATION_ERROR, error);
  commit(Mutations.SET_VALIDATION_RESULT, data);
};

const loadDocument = async (commit: Commit, type: DocumentType) => {
  commit(Mutations.SET_DOCUMENT_LOADING, type);
  const { data, error } = await service.loadDocument(type);
  commit(Mutations.SET_DOCUMENT_ERROR, { type, error });
  commit(Mutations.SET_DOCUMENT, { type, data });
};

const actions: ActionTree<State, Actions> = {
  [Actions.VALIDATE_DOCUMENT]: async ({ commit }, id: string) =>
    validateDocument(commit, id),
  [Actions.LOAD_DOCUMENT]: async ({ commit }, type: DocumentType) =>
    loadDocument(commit, type)
};

export default actions;
