
















import { defineComponent } from "@vue/composition-api";
import { useStore } from "@/lib/useStore";
import { Modules } from "@/store/types";
import { Actions } from "@/store/modules/vimbApi/types";

export default defineComponent({
  setup() {
    const { useActions } = useStore(Modules.VIMB_API);
    const {
      [Actions.REQUEST_TOKEN_FROM_PARENT]: requestLoginToken
    } = useActions([Actions.REQUEST_TOKEN_FROM_PARENT]);
    requestLoginToken();
  }
});
