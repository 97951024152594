export const orError = <T extends unknown, E = Error>(
  fn: () => T,
  errorFn?: (error: Error) => E
): T | E => {
  try {
    return fn();
  } catch (error) {
    if (errorFn) return errorFn(error);
    return error;
  }
};
