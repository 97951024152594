import {
  Erlaubnisumfang,
  ErlaubnisumfangType
} from "@/types/vimbApi/erlaubnisinhaber/erlaubnisumfang";
import { removeFalseDelete, removeNewDeleted } from "./delete";
import { RawErlaubnisumfang } from "./types";

const getErlaubnisumfangByType = (
  erlaubnisumfaenge: RawErlaubnisumfang[],
  type: ErlaubnisumfangType
) => erlaubnisumfaenge.find(erlaubnisumfang => erlaubnisumfang.key === type);

const mapErlaubnisumfangToRaw = (deleteAll: boolean) => (
  erlaubnisumfang: Erlaubnisumfang
): RawErlaubnisumfang => ({
  id: erlaubnisumfang.id,
  key: erlaubnisumfang.type,
  value: erlaubnisumfang.active,
  del: deleteAll || !erlaubnisumfang.active
});

export const toErlaubnisumfang = (
  erlaubnisumfang: RawErlaubnisumfang[] = []
): { [key in ErlaubnisumfangType]: Erlaubnisumfang } =>
  Object.fromEntries(
    Object.entries(ErlaubnisumfangType)
      .map(([key, type]) => ({
        key,
        type,
        erlaubnisumfang: getErlaubnisumfangByType(erlaubnisumfang, type)
      }))
      .map(({ key, type, erlaubnisumfang: { id, value } = {} }) => ({
        key,
        type,
        erlaubnisumfang: { id, type, active: !!value }
      }))
      .map(({ type, erlaubnisumfang }) => [type, erlaubnisumfang])
  ) as { [key in ErlaubnisumfangType]: Erlaubnisumfang };

export const fromErlaubnisumfang = (
  erlaubnisumfang: { [key in ErlaubnisumfangType]: Erlaubnisumfang },
  deleteAll: boolean
): RawErlaubnisumfang[] =>
  Object.values(erlaubnisumfang)
    .map(mapErlaubnisumfangToRaw(deleteAll))
    .filter(removeNewDeleted)
    .map(removeFalseDelete);
