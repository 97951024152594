




import router from "@/router";
import { isEmbedded } from "@/services/embedded";
import { defineComponent, ref, watch } from "@vue/composition-api";

const hideAppBar = () => isEmbedded() || router.currentRoute.meta?.hideMenu;

export default defineComponent({
  setup() {
    const noAppBar = ref<boolean>();
    watch(
      () => router.currentRoute,
      () => (noAppBar.value = hideAppBar()),
      { immediate: true }
    );
    return { noAppBar };
  }
});
