import { ActionTree, Commit } from "vuex";
import { RootState } from "@/store/types";
import service from "@/services/locations";
import { Location } from "@/types/locations/location";
import { Actions, Mutations, State } from "./types";
import { DeletableContact } from "@/types/locations/contact";

const getLocations = async (commit: Commit) => {
  commit(Mutations.REMOVE_ALL_LOCATIONS);
  commit(Mutations.SET_LOCATION_LOADING, 0);
  const { data = [], error } = await service.load();
  if (error) commit(Mutations.SET_LOCATION, { value: { id: 0 }, error });
  else commit(Mutations.REMOVE_LOCATION, 0);
  data.map((item: Location) => commit(Mutations.SET_LOCATION, item));
};

const getLocation = async (commit: Commit, id: number) => {
  commit(Mutations.SET_LOCATION_LOADING, id);
  const { data: value = { id }, error } = await service.get(id);
  commit(Mutations.SET_LOCATION, { value, error });
};

const remove = async (commit: Commit, id: number) => {
  commit(Mutations.SET_LOCATION_LOADING, id);
  if (id <= 0) return commit(Mutations.REMOVE_LOCATION, id);
  const { error } = await service.remove(id);
  if (error) commit(Mutations.SET_LOCATION_ERROR, { id, error });
  else commit(Mutations.REMOVE_LOCATION, id);
};

const save = async (commit: Commit, location: Location) => {
  const { id, ...postData } = location;
  if (id === 0) return;
  commit(Mutations.SET_LOCATION_LOADING, id);
  const { data, error } =
    id > 0 ? await service.put(id, location) : await service.post(postData);
  if (error) commit(Mutations.SET_LOCATION_ERROR, { id, error });
  if (data) commit(Mutations.SET_LOCATION, data);
  if (!error && id !== data?.id) commit(Mutations.REMOVE_LOCATION, id);
};

const create = async (
  commit: Commit,
  location: Omit<Location, "id">
): Promise<Location> => {
  const id = service.create();
  const newLocation = { id, ...location, contacts: location.contacts || [] };
  commit(Mutations.SET_LOCATION, newLocation);
  return newLocation;
};

const createContact = async (
  commit: Commit,
  {
    locationId,
    contact
  }: { locationId: number; contact: Omit<DeletableContact, "id"> }
): Promise<DeletableContact> => {
  const id = service.createContact();
  const newContact = { id, ...contact };
  commit(Mutations.SET_LOCATION_CONTACT, { locationId, contact: newContact });
  return newContact;
};

const setContact = async (
  commit: Commit,
  { locationId, contact }: { locationId: number; contact: DeletableContact }
) => commit(Mutations.SET_LOCATION_CONTACT, { locationId, contact: contact });

const actions: ActionTree<State, RootState> = {
  [Actions.GET]: async ({ commit }, id?: number) =>
    id ? getLocation(commit, id) : getLocations(commit),
  [Actions.REMOVE]: async ({ commit }, id: number) => remove(commit, id),
  [Actions.CREATE]: async ({ commit }, location: Omit<Location, "id">) =>
    create(commit, location),
  [Actions.SAVE]: async ({ commit }, location: Location) =>
    save(commit, location),
  [Actions.CREATE_CONTACT]: async (
    { commit },
    contact: { locationId: number; contact: Omit<DeletableContact, "id"> }
  ) => createContact(commit, contact),
  [Actions.SET_CONTACT]: async (
    { commit },
    contact: { locationId: number; contact: DeletableContact }
  ) => setContact(commit, contact)
};

export default actions;
