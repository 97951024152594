import { Routes } from "@/router/types";
import { RouteConfig } from "vue-router";

export const recommendation = (): Array<RouteConfig> => [
  {
    path: "/mvp/social/recommendation",
    name: Routes.RECOMMENDATION,
    component: () => import("@/views/settings/social/Recommendation.vue"),
    meta: { authorization: { require: true } }
  }
];
