import Vue from "vue";
import Vuex, { StoreOptions } from "vuex";
import { RootState, Modules } from "./types";

import vimbApi from "./modules/vimbApi";
import erlaubnisinhaber from "./modules/erlaubnisinhaber";
import locations from "./modules/locations";
import maklerdokumente from "./modules/maklerdokumente";
import recommendation from "./modules/social/recommendation";

Vue.use(Vuex);

const options: StoreOptions<RootState> = {
  modules: {
    [Modules.VIMB_API]: vimbApi,
    [Modules.ERLAUBNISINHABER]: erlaubnisinhaber,
    [Modules.LOCATIONS]: locations,
    [Modules.MAKLERDOKUMENTE]: maklerdokumente,
    [Modules.RECOMMENDATION]: recommendation
  }
};

export const store = new Vuex.Store(options);

export default store;
