import { ActionTree } from "vuex";
import { RootState } from "@/store/types";
import { Actions, Mutations, State } from "./types";
import { fetch } from "@/services/vimbApi/fetch";
import { requestLoginToken } from "@/services/embedded";

const actions: ActionTree<State, RootState> = {
  [Actions.LOAD_ME]: async ({ state: { token, clientId }, commit }) => {
    if (!token || !clientId) return;
    const { status, data = {} } = await fetch("users/me");
    if (status === 200) commit(Mutations.SET_USER, data.user);
  },
  [Actions.REQUEST_TOKEN_FROM_PARENT]: async ({ commit }) => {
    try {
      const token = await requestLoginToken();
      if (!token) return false;
      commit(Mutations.SET_TOKEN, token);
      return token;
    } catch (error) {
      // @TODO show banner
    }
  }
};

export default actions;
