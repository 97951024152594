export enum LocationType {
  BETRIEBLICHE_ANSCHRIFT = "erlaubnisinhaber.betriebliche_anschrift",
  ERLAUBNISBEHOERDE = "erlaubnisinhaber.erlaubnisbehoerde",
  REGISTERBEHOERDE = "erlaubnisinhaber.registerbehoerde",
  PERSONENHANDELSGESELLSCHAFT = "erlaubnisinhaber.personenhandelsgesellschaft",
  UNKNOWN = ""
}

export interface Location {
  id?: number;
  type: LocationType;
  name?: string;
  city?: string;
  zip?: string;
  street?: string;
  houseNumber?: string;
  co?: string;
  active?: boolean;
}
