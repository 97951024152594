import { Params, Routes } from "@/router/types";
import { RouteConfig } from "vue-router";

export const preview = (): Array<RouteConfig> => [
  {
    path: "/mvp/maklerdokument/preview/:type",
    name: Routes.MAKLERDOKUMENTE,
    props(route) {
      const params: Params = { ...route.params };
      const { type } = params;
      return { type };
    },
    component: () => import("@/views/settings/Maklerdokumente.vue"),
    meta: {
      authorization: {
        require: true
      }
    }
  }
];
