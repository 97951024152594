import { Params, Routes } from "@/router/types";
import { RouteConfig } from "vue-router";

export const erlaubnisinhaber = (): Array<RouteConfig> => [
  {
    path: "/mvp/erlaubnisinhaber/:id?",
    name: Routes.ERLAUBNISINHABER,
    props(route) {
      const params: Params = { ...route.params };
      const id = parseInt(params.id) || undefined;
      const create = params.id === "new";
      return { id, create };
    },
    component: () => import("@/views/settings/Erlaubnisinhaber.vue"),
    meta: {
      authorization: {
        require: true
      }
    }
  }
];
