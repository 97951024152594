import {
  Vertreter,
  VertreterType
} from "@/types/vimbApi/erlaubnisinhaber/vertreter";
import { RawVertreter } from "./types";

const toVertreterType = (
  search: string | undefined
): VertreterType | undefined => {
  if (search === "Geschäftsführer") return VertreterType.GESCHAEFTFUEHRER;
  if (search === "Vorstand") return VertreterType.VORSTAND;
  if (search === "Inhaber") return VertreterType.INHABER;
  if (search === "Komplementär") return VertreterType.KOMPLEMENTAER;
};

export const toVertreter = (vertreter: RawVertreter[] = []): Vertreter[] =>
  vertreter.map(item => ({
    id: item.id,
    vorname: item.vorname,
    nachname: item.nachname,
    funktion: toVertreterType(item.funktion),
    active: true
  }));

export const fromVertreter = (
  vertreter: Vertreter[] = [],
  deleteAll: boolean
): RawVertreter[] =>
  vertreter
    .map(item => ({
      id: item.id,
      vorname: item.vorname,
      nachname: item.nachname,
      funktion: item.funktion,
      del: deleteAll || !item.active
    }))
    .filter(({ del, id }) => !!id || !del)
    .map(({ del, ...data }) => (del ? { ...data, del } : data));
