import { AxiosResponse } from "axios";
import { DocumentValidationResult } from "@/types/maklerdokumente/DocumentValidationResult";

// converting array-buffer to json-object
// required for error-responses of document-requests
const arrayBufferToJson = (data: ArrayBuffer) => {
  const dataArray = Array.from(new Uint8Array(data));
  const charArray = dataArray.map(charCode => String.fromCharCode(charCode));
  return JSON.parse(charArray.join(""));
};

const error = (data: { message?: string; error?: string }) => {
  const { message, error } =
    data instanceof ArrayBuffer ? arrayBufferToJson(data) : data;
  return Error(message || error || "Unbekannter Fehler");
};

export const fromDocumentValidation = (data: {
  ergebnis?: DocumentValidationResult;
}) =>
  data?.ergebnis &&
  Object.fromEntries(
    Object.entries(data.ergebnis).map(([key, value]) => [
      key,
      // fixing inconsistent data-types from api: array means no error
      Array.isArray(value) ? {} : value
    ])
  );

export const fromDocument = (data: ArrayBuffer) => data;

export const response = <From, To>(
  { status, data }: AxiosResponse,
  transform: (data: From) => To
) => {
  if (status >= 400 || !data) return { error: error(data) };
  try {
    return { data: transform(data) };
  } catch (error) {
    return { error };
  }
};
