import { Action } from "vuex";

export interface RootState {
  version?: number;
}

export enum Modules {
  VIMB_API = "VimbApi",
  ERLAUBNISINHABER = "Erlaubnisinhaber",
  LOCATIONS = "locations",
  MAKLERDOKUMENTE = "maklerdokumente",
  RECOMMENDATION = "Recommendation"
}

export type ActionTree<State, Actions extends string> = {
  [key in Actions]: Action<State, RootState>;
};
