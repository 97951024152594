import { orError } from "@/lib/tools/orError";
import { Recommendation } from "@/types/social/recommendation/recommendation";
import { RecommendationError } from "@/types/social/recommendation/error";
import { AxiosResponse } from "axios";
import { RawError, Response } from "./types";

const fromResponse = (raw?: Response): Recommendation => ({
  create: !raw,
  url: raw?.url || "",
  subject: raw?.subject || "",
  message: raw?.message || "",
  description: raw?.description || ""
});

const cleanFieldError = (value: string) => value.replace(/^.*=>/g, "");

const cleanFieldErrors = (errors: { [key: string]: string[] }) =>
  Object.entries(errors)
    .map(([key, values]) => ({ key, values: values.map(cleanFieldError) }))
    .reduce((obj, { key, values }) => ({ ...obj, [key]: values }), {});

const error = ({
  status_code: statusCode = 0,
  message,
  errors = {}
}: RawError): RecommendationError => {
  const cleanErrors = cleanFieldErrors(errors);
  const [firstError] = Object.values(cleanErrors).flat() as string[];
  return { statusCode, message: firstError || message, errors: cleanErrors };
};

export const response = ({
  status,
  data
}: AxiosResponse): Recommendation | RecommendationError => {
  if (status === 404) return fromResponse();
  if (status >= 400) return error(data);
  return orError(() => fromResponse(data), error);
};

export const deleteResponse = ({
  status,
  data
}: AxiosResponse): Recommendation | RecommendationError => {
  if (status >= 400) return error(data);
  return orError(() => fromResponse(), error);
};
