import { DocumentType } from "@/types/maklerdokumente/DocumentTypes";
import { DocumentValidationResult } from "@/types/maklerdokumente/DocumentValidationResult";

export enum States {
  VALIDATING = "validating",
  VALIDATION_RESULT = "validationResult",
  VALIDATION_ERROR = "validationError",
  DOCUMENT = "document",
  DOCUMENT_LOADING = "documentLoading",
  DOCUMENT_ERROR = "documentError"
}

export enum Actions {
  VALIDATE_DOCUMENT = "validateDocument",
  LOAD_DOCUMENT = "loadDocument"
}

export enum Mutations {
  SET_VALIDATING = "setValidating",
  SET_VALIDATION_RESULT = "setValidationResult",
  SET_VALIDATION_ERROR = "setValidationError",
  SET_DOCUMENT_LOADING = "setDocumentLoading",
  SET_DOCUMENT_ERROR = "setDocumentError",
  SET_DOCUMENT = "setDocument"
}

export enum Getters {
  GET_VALIDATION = "getValidation",
  GET_DOCUMENT = "getDocument"
}

export type State = {
  [States.VALIDATING]: boolean;
  [States.VALIDATION_RESULT]?: DocumentValidationResult;
  [States.VALIDATION_ERROR]?: Error;
  [States.DOCUMENT]: { [key in DocumentType]?: string };
  [States.DOCUMENT_LOADING]: { [key in DocumentType]?: boolean };
  [States.DOCUMENT_ERROR]: { [key in DocumentType]?: Error };
};
