import { RootState } from "@/store/types";
import { Module } from "vuex";
import mutations from "./mutations";
import actions from "./actions";
import getters from "./getters";
import { State, States } from "./types";

export const state: State = {
  [States.VALIDATING]: false,
  [States.DOCUMENT]: {},
  [States.DOCUMENT_LOADING]: {},
  [States.DOCUMENT_ERROR]: {}
};

const module: Module<State, RootState> = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};

export default module;
