import { Erlaubnisinhaber } from "@/types/vimbApi/erlaubnisinhaber/erlaubnisinhaber";
import {
  Geschaeftsform,
  geschaeftsformenConfig
} from "@/types/vimbApi/erlaubnisinhaber/geschaeftsform";
import { TaetigkeitType } from "@/types/vimbApi/erlaubnisinhaber/taetigkeit";
import { RawErlaubnisinhaber } from "./types";
import { fromHandelsstand, toHandelstand } from "./handelsstand";
import { toGeschaeftsform } from "./geschaeftsform";
import { fromTaetigkeiten, toTaetigkeiten } from "./taetigkeiten";
import { fromLocations, toLocations } from "./locations";
import { fromVertreter, toVertreter } from "./vertreter";
import { fromErlaubnisumfang, toErlaubnisumfang } from "./erlaubnisumfang";

const has34fTaetigkeit = (erlaubnisinhaber: Erlaubnisinhaber): boolean => {
  return !!erlaubnisinhaber.taetigkeiten[TaetigkeitType.TYPE34F]?.active;
};

const geschaeftsformHasFirmenname = ({
  geschaeftsform = Geschaeftsform.UNKNOWN
}: Erlaubnisinhaber): boolean =>
  !!geschaeftsformenConfig[geschaeftsform]?.hasFirmenname;
const geschaeftsformHasName = ({
  geschaeftsform = Geschaeftsform.UNKNOWN
}: Erlaubnisinhaber): boolean =>
  !!geschaeftsformenConfig[geschaeftsform]?.hasName;
const geschaeftsformHasVertreter = ({
  geschaeftsform = Geschaeftsform.UNKNOWN
}: Erlaubnisinhaber): boolean =>
  !!geschaeftsformenConfig[geschaeftsform]?.hasVertreter;
const geschaeftsformHasHandelsgesellschaften = ({
  geschaeftsform = Geschaeftsform.UNKNOWN
}: Erlaubnisinhaber): boolean =>
  !!geschaeftsformenConfig[geschaeftsform]?.hasHandelsgesellschaften;

export const toErlaubnisinhaber = (
  data: RawErlaubnisinhaber = {}
): Erlaubnisinhaber => ({
  id: data.id,
  geschaeftsform: toGeschaeftsform(data.geschaeftsform),
  handelsstand: toHandelstand(data.hgb),
  handelsregisternummer: data.handelsregister_nr,
  amtsgerichtOrt: data.amtsgericht_ort,
  firmenname: data.firmenname,
  vorname: data.vorname,
  nachname: data.nachname,
  anbieterliste: data.anbieterliste?.replace(/ {2}(\n|$)/g, "$1"),
  erlaubnisumfang: toErlaubnisumfang(data.erlaubnisumfang),
  taetigkeiten: toTaetigkeiten(data.taetigkeiten),
  vertreter: toVertreter(data.vertreter),
  locations: toLocations(data.locations)
});

export const fromErlaubnisinhaber = (data: Erlaubnisinhaber) => ({
  id: data.id,
  geschaeftsform: data.geschaeftsform,
  hgb: fromHandelsstand(data.handelsstand),
  // eslint-disable-next-line @typescript-eslint/camelcase
  handelsregister_nr: data.handelsregisternummer,
  // eslint-disable-next-line @typescript-eslint/camelcase
  amtsgericht_ort: data.amtsgerichtOrt,
  firmenname: geschaeftsformHasFirmenname(data) ? data.firmenname : "",
  vorname: geschaeftsformHasName(data) ? data.vorname : "",
  nachname: geschaeftsformHasName(data) ? data.nachname : "",
  anbieterliste: has34fTaetigkeit(data)
    ? data.anbieterliste?.replace(/(\n|$)/g, "  $1")
    : "",
  erlaubnisumfang: fromErlaubnisumfang(
    data.erlaubnisumfang,
    !has34fTaetigkeit(data)
  ),
  vertreter: fromVertreter(data.vertreter, !geschaeftsformHasVertreter(data)),
  taetigkeiten: fromTaetigkeiten(data.taetigkeiten),
  locations: fromLocations(
    data.locations,
    !geschaeftsformHasHandelsgesellschaften(data)
  )
});
