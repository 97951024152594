export type Id = { id: number };

export type List<Type> = { [id: number]: Type };

export type MaybeCrudItem<Type extends Id> = CrudItem<Type> | undefined;

export type CrudItem<Type extends Id> = {
  loading: boolean;
  value: Type | (Partial<Type> & Id);
  error?: Error;
  id?: undefined;
};

export type NewCrudItem<Type extends Id> = Omit<CrudItem<Type>, "value"> & {
  value: Omit<Type, "id">;
};

export type CrudList<Type extends Id> = List<CrudItem<Type>>;

export const isCrudItem = <Type extends Id>(
  item: MaybeCrudItem<Type> | Type
): item is CrudItem<Type> =>
  !!item && (item as CrudItem<Type>).id === undefined;
