import { fetch, Methods } from "@/services/vimbApi/fetch";
import { Recommendation } from "@/types/social/recommendation/recommendation";
import { request } from "./request";
import { deleteResponse, response } from "./response";

const URL = "/mvp/social/recommendation";

export const get = async () => response(await fetch(URL));

export const getDefault = async () => response(await fetch(URL + "/default"));

export const post = async (recommendation: Recommendation) =>
  response(await fetch(URL, Methods.POST, request(recommendation)));

export const put = async (recommendation: Recommendation) =>
  response(await fetch(URL, Methods.PUT, request(recommendation)));

export const remove = async () =>
  deleteResponse(await fetch(URL, Methods.DELETE));

export default { get, getDefault, post, put, remove };
