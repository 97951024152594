import { Routes } from "@/router/types";
import { RouteConfig } from "vue-router";

export const datenschutz = (): Array<RouteConfig> => [
  {
    path: "/mvp/datenschutz",
    name: Routes.DATENSCHUTZ,
    component: () => import("@/views/settings/Datenschutz.vue"),
    meta: {
      authorization: {
        require: true
      }
    }
  }
];
