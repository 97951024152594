export enum VertreterType {
  GESCHAEFTFUEHRER = "Geschäftsführer",
  VORSTAND = "Vorstand",
  INHABER = "Inhaber",
  KOMPLEMENTAER = "Komplementär"
}

export interface Vertreter {
  id?: number;
  vorname?: string;
  nachname?: string;
  funktion?: VertreterType;
  active?: boolean;
}
