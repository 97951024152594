export enum Geschaeftsform {
  AG = "AG",
  GMBH = "GmbH",
  GMBHCOKG = "GmbH & Co. KG",
  GBR = "GbR",
  OHG = "OHG",
  UG = "UG",
  KG = "KG",
  EK = "e. K.",
  EINZELKAUFMANN = "Einzelkaufmann",
  UNKNOWN = ""
}

export interface GeschaeftsformConfig {
  hasFirmenname?: boolean;
  hasName?: boolean;
  hasVertreter?: boolean;
  hasHandelsgesellschaften?: boolean;
}

export type GeschaetfsformenConfig = {
  [key in Geschaeftsform]: GeschaeftsformConfig;
};

export const geschaeftsformenConfig: GeschaetfsformenConfig = {
  [Geschaeftsform.AG]: { hasFirmenname: true, hasVertreter: true },
  [Geschaeftsform.GMBH]: { hasFirmenname: true, hasVertreter: true },
  [Geschaeftsform.GMBHCOKG]: {
    hasFirmenname: true,
    hasVertreter: true,
    hasHandelsgesellschaften: true
  },
  [Geschaeftsform.GBR]: { hasName: true },
  [Geschaeftsform.OHG]: {
    hasFirmenname: true,
    hasName: true,
    hasHandelsgesellschaften: true
  },
  [Geschaeftsform.UG]: { hasFirmenname: true, hasVertreter: true },
  [Geschaeftsform.KG]: { hasName: true, hasHandelsgesellschaften: true },
  [Geschaeftsform.EK]: { hasFirmenname: true, hasName: true },
  [Geschaeftsform.EINZELKAUFMANN]: { hasName: true },
  [Geschaeftsform.UNKNOWN]: {}
};
